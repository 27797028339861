import { extendModule } from '~/helpers/vuex/extendModule/extendModule';
import type { CartTrackingMutations, CartTrackingState } from '~/types/vuex/CartTracking';

const state = (): CartTrackingState => ({
  beginCheckoutEventCartVersion: null
});

const mutations: CartTrackingMutations = {
  setBeginCheckoutEventCartVersion(state: CartTrackingState, payload: string) {
    state.beginCheckoutEventCartVersion = payload;
  }
};

export default extendModule({
  state,
  mutations
});
