import type { PaymentMethodsConfiguration } from '@adyen/adyen-web';
import { Logger } from '@vue-storefront/core';
import {
  useI18n,
  useCheckoutAddresses,
  usePayNowEvent
} from '~/composables';
import { GooglePayResponse } from '~/types/GooglePayResponse';
import { AddressType as Address } from '~/types/AddressType';
import type { AddressType } from '~/types/AddressType';
import { PAYMENT_METHODS } from '~/constants/paymentMethods';

function buildGooglePayAddress(stateData: GooglePayResponse, addressType: AddressType) {
  const address = addressType === Address.Shipping
    ? stateData.authorizedEvent.shippingAddress
    : stateData.authorizedEvent.paymentMethodData.info?.billingAddress;

  if (!address) {
    throw new Error('Adress from Google express payment is missing');
  }

  const additionalAddressInfo = address.address2 + (address.address3 ? `, ${address.address3}` : '');

  return {
    firstName: address.name,
    // the firtName includes the whole name but lastName is needed to be empty instead of undefined
    lastName: '',
    phone: address.phoneNumber,
    country: address.countryCode,
    city: address.locality,
    postalCode: address.postalCode,
    streetName: address.address1,
    // the streetName includes the whole address but streetNumber is needed to be empty instead of undefined
    streetNumber: '',
    additionalAddressInfo
  };
}

export default function () {
  const { countryCode } = useI18n();
  const { setAddressesDetails } = useCheckoutAddresses();
  const { callPayNowEvent } = usePayNowEvent();
  // to be updated when a new express payment method is added
  const expressPaymentsOptionsSize = 1;

  function configureExpressCheckout(paymentMethodsConfiguration: PaymentMethodsConfiguration) {
    paymentMethodsConfiguration.googlepay = {
    // TRAN-1761 - email, billing/shipping details to be set to commercetools
      onAuthorized: async (stateData: GooglePayResponse, actions) => {
        try {
          await setGooglePayCustomerDetails(stateData);
          actions.resolve();
        } catch (error) {
          Logger.error('Error in Google express pay onAuthorized event', {
            message: error?.message
          });
          actions.reject();
        }
      },
      isExpress: true,
      buttonColor: 'black',
      buttonType: 'plain',
      buttonSizeMode: 'fill',
      shippingAddressRequired: true,
      emailRequired: true,
      shippingAddressParameters: {
        allowedCountryCodes: [countryCode.value.toUpperCase()],
        phoneNumberRequired: true
      },
      billingAddressRequired: true,
      billingAddressParameters: {
        format: 'FULL',
        phoneNumberRequired: true
      }
    };
  }

  function onClickHandler(resolve: () => void) {
    callPayNowEvent(PAYMENT_METHODS.GOOGLEPAY);
    resolve();
  }

  function configureGooglePayOnClick(paymentMethodsConfiguration: PaymentMethodsConfiguration) {
    if (paymentMethodsConfiguration?.googlepay) {
      paymentMethodsConfiguration.googlepay.onClick = onClickHandler;
      return;
    }

    paymentMethodsConfiguration.googlepay = {
      onClick: onClickHandler
    };
  }

  async function setGooglePayCustomerDetails(stateData: GooglePayResponse) {
    await setAddressesDetails(buildGooglePayAddress(stateData, Address.Shipping),
      buildGooglePayAddress(stateData, Address.Billing), stateData.authorizedEvent.email ?? '');
  }

  return {
    configureExpressCheckout,
    expressPaymentsOptionsSize,
    configureGooglePayOnClick
  };
}
