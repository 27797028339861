import type { LineItem, ProductVariant } from '@vsf-enterprise/commercetools-types';
import { sharedRef, Logger } from '@vue-storefront/core';
import type { Ref } from '@nuxtjs/composition-api';
import { watch } from '@nuxtjs/composition-api';
import { useExtraGuaranteePrice } from '../useExtraGuaranteePrice';
import { useExtraGuaranteeExcluded } from '../useExtraGuaranteeExcluded';
import virtualProductSku from '~/constants/virtualProductSku';
import {
  useCartExtended,
  useUserOrder,
  useStoreVsfExtended,
  useIntegrations,
  useIsPage
} from '~/composables';
import { STANDARD_PRODUCT } from '~/constants/productType';
import { GUARANTEE_PLACEMENT } from '~/constants/guaranteePlacement';
import {
  SKU_FOR_EXTRA_GUARANTEE,
  PRODDUCT_NAME_FOR_EXTRA_GUARANTEE,
  PRODDUCT_IMAGE_FOR_EXTRA_GUARANTEE
} from '~/constants/extraGuarantee';

type setExtraGuarenteeInfoParams = {
  price: number;
  name: string;
  image: string;
  sku: string;
};

export const useExtraGuarantee = () => {
  const { cart } = useCartExtended();
  const { order } = useUserOrder();
  const { isExtraGuaranteeEnabled } = useStoreVsfExtended();
  const { $cia } = useIntegrations();
  const { isOnPaymentPage } = useIsPage();

  const {
    setExtraGuaranteePrice,
    getExtraGuaranteePercentage
  } = useExtraGuaranteePrice();

  const {
    isGuaranteeExcluded
  } = useExtraGuaranteeExcluded();

  // TO DO INSP-4165 - Create useExtraGuarantee store
  const skuForExtraGuarantee: Ref<string | null> = sharedRef(null, SKU_FOR_EXTRA_GUARANTEE);
  const productNameForExtraGuarntee: Ref<string | null> = sharedRef(null, PRODDUCT_NAME_FOR_EXTRA_GUARANTEE);
  const productImageForExtraGuarntee: Ref<string | null> = sharedRef(null, PRODDUCT_IMAGE_FOR_EXTRA_GUARANTEE);
  const setProductNameForExtraGuarantee = (name: string) => {
    productNameForExtraGuarntee.value = name;
  };
  const setProductImageForExtraGuarntee = (name: string) => {
    productImageForExtraGuarntee.value = name;
  };
  const setSkuForExtraGuarantee = (sku: string) => {
    skuForExtraGuarantee.value = sku;
  };
  const getSkuForExtraGuarantee = (): string | null => {
    return skuForExtraGuarantee.value;
  };

  const skuHasAddedExtraGuarantee = (productSku: ProductVariant['sku']) => {
    return !!cart.value?.lineItems?.find((item: LineItem) =>
      isExtraGuaranteeItem(item, productSku)
    )?.custom?.customFieldsRaw?.[0]?.value;
  };

  const isExtraGuaranteeItem = (item: LineItem, productSku: ProductVariant['sku']) => {
    return item.variant?.sku === virtualProductSku &&
      item.custom?.customFieldsRaw?.[0]?.value === productSku &&
      item.productType?.key !== STANDARD_PRODUCT;
  };

  const cartItemExtraGuaranteeExcluded = (productSku: ProductVariant['sku']) => {
    return cart.value?.lineItems?.some((item: LineItem) => {
      if (item.variant?.sku === productSku) {
        return isGuaranteeExcluded(item.variant);
      }
      return false;
    }
    );
  };

  const extraGuaranteeParentSku = (product: LineItem) => {
    return product.custom?.customFieldsRaw?.[0]?.value;
  };

  const extraGuaranteeParentTitle = (productSku: ProductVariant['sku']) => {
    return (cart.value || order.value)?.lineItems?.find((item: LineItem) => {
      return item?.variant?.sku === productSku;
    })?.name;
  };

  const shouldAddExtraGuarantee = (product: LineItem) => {
    const sku = product.variant?.sku;
    return isExtraGuaranteeEnabled.value &&
      !skuHasAddedExtraGuarantee(sku) &&
      !isVirtualProduct(sku) &&
      !isOnPaymentPage.value &&
      !cartItemExtraGuaranteeExcluded(sku);
  };

  const isVirtualProduct = (productSku: ProductVariant['sku']) => {
    return productSku === virtualProductSku;
  };

  const setExtraGuaranteeInfo = ({ price, name, image, sku }: setExtraGuarenteeInfoParams) => {
    setExtraGuaranteePrice(price);
    setSkuForExtraGuarantee(sku);
    setProductNameForExtraGuarantee(name);
    setProductImageForExtraGuarntee(image);
  };

  const sendCiaViewGuarantee = (type: typeof GUARANTEE_PLACEMENT[keyof typeof GUARANTEE_PLACEMENT]) => {
    watch(isExtraGuaranteeEnabled, (value) => {
      if (value) {
        cart.value?.lineItems.forEach((product) => {
          if (shouldAddExtraGuarantee(product)) {
            const percentage = getExtraGuaranteePercentage();
            try {
              $cia.event.viewGuarantee(product.variant?.sku, type, percentage);
            } catch (error) {
              Logger.error(`cia|viewGuarantee error: ${error}`);
            }
          }
        });
      }
    }, { immediate: true });
  };

  return {
    skuHasAddedExtraGuarantee,
    getSkuForExtraGuarantee,
    isVirtualProduct,
    extraGuaranteeParentSku,
    extraGuaranteeParentTitle,
    setExtraGuaranteeInfo,
    isExtraGuaranteeItem,
    shouldAddExtraGuarantee,
    sendCiaViewGuarantee,
    cartItemExtraGuaranteeExcluded,
    productNameForExtraGuarntee,
    productImageForExtraGuarntee
  };
};
