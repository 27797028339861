import { extendModule } from '~/helpers/vuex/extendModule/extendModule';
import { AdyenErrorState } from '~/types/vuex/AdyenError';

const state = () => ({
  error: null
});

const mutations = {
  setError(state: AdyenErrorState, payload: AdyenErrorState['error']) {
    state.error = payload;
  }
};

export default extendModule({
  state,
  mutations
});
