import Currency from '../../types/localization/currency';
import currencies from '../../i18n/currencies';
import languageCodes from '../../i18n/enums/languageCodes';
import { currencyCodes } from '../../i18n/enums/currencyCodes';
import { defaultCountryCode } from '../../i18n/enums/countryCodes';
import { getEnvironmental } from '../../helpers/env/getEnvironmental';
import { publicEnvironmental } from '../../config/publicEnvironmental';
import { isProduction, isPreProduction } from '../../helpers/env/checkEnvironmentType';
import { LANGUAGE_AND_COUNTRY_LENGTH } from '../../constants/localization';
import { hardcodeHostnameAsPreproduction } from '../../helpers/env/preprod';
import stageConfig from '../../config/stage';
import { CountryConfigurationOptions } from './countryConfigurationOptions';
import {
  LanguageIdentification,
  ParcelPerformByLanguage,
  BloomreachKeyByLanguage,
  CountryCodeValue
} from './localizationHelperTypes';

const processDomainForPreprod = (domain: string) => {
  if (isPreProduction()) {
    return hardcodeHostnameAsPreproduction(domain);
  } else {
    return domain;
  }
};

class CountryLocalization {
  readonly DOMAIN_PREFIX = 'www.expondo';
  readonly STORE_PREFIX = 'expondo';
  static readonly ALGOLIA_INDEX_NAME_SEPARATOR = '.';
  public prodDomain: string;
  public topLevelDomain: string;
  public isDefault: boolean = false;
  public fallbacks: {[key: string] : string[]} = {};
  public countryCode: CountryCodeValue;
  public label: string;
  public currencyCode: ValueOf<typeof currencyCodes>;
  public storeId: string;

  private _parcelPerformByLanguage: Array<ParcelPerformByLanguage>;
  private _bloomreachKeyByLanguage: Array<BloomreachKeyByLanguage>;
  private _consentManagerScriptUrls: Array<{ language: ValueOf<typeof languageCodes>, url: string }>;
  private _languageAndCountryFallbacks: Array<LanguageIdentification>;
  private _additionalAcceptedLanguages: Array<LanguageIdentification>;
  private _customDomainEnding: string | null;
  private _customStoreEnding: string | null;
  private _languageAndCountries: string[] = [];
  private _languages: Array<ValueOf<typeof languageCodes>>;

  constructor (countryConfig: CountryConfigurationOptions) {
    this._languages = countryConfig.languages;
    this._parcelPerformByLanguage = countryConfig.parcelPerformByLanguage;
    this._bloomreachKeyByLanguage = countryConfig.bloomreachKeyByLanguage;
    this._consentManagerScriptUrls = countryConfig.consentManagerScriptUrls || [];
    this._languageAndCountryFallbacks = countryConfig.languageAndCountryFallbacks ?? [];
    this._additionalAcceptedLanguages = countryConfig.additionalAcceptedLanguages ?? [];
    this._customDomainEnding = countryConfig.customDomainEnding ?? null;
    this._customStoreEnding = countryConfig.customStoreEnding ?? null;

    this.countryCode = countryConfig.countryCode;
    this.label = countryConfig.label;
    this.currencyCode = countryConfig.currencyCode;
    this.isDefault = this.countryCode === defaultCountryCode;
    this.prodDomain = `${this.DOMAIN_PREFIX}.${this._customDomainEnding || this.countryCode}`;
    this.topLevelDomain = this._customDomainEnding || this.countryCode;
    this.storeId = `${this.STORE_PREFIX}_${this._customStoreEnding || this.countryCode}`;

    for (const language of this._languages) {
      this._languageAndCountries.push(this.getLanguageAndCountry(language));
    }
  }

  get flagFile(): string {
    return `flag_${this.countryCode}.svg`;
  }

  get isMultiLanguage(): boolean {
    return Object.entries(this._languageAndCountries).length > 1;
  }

  get currency() : Currency {
    return currencies[this.currencyCode];
  }

  public getVueI18nFallbacks(): {[key: string] : string[]} {
    const fallbacks: {[key: string] : string[]} = {};

    for (const { language, countryCode } of this._languageAndCountryFallbacks) {
      const source = this.getLanguageAndCountry(language);
      fallbacks[source] = [this.getLanguageAndCountry(language, countryCode)];
    }
    return fallbacks;
  }

  public isDefaultLanguage (language: ValueOf<typeof languageCodes>| string): boolean {
    return this.getDefaultLanguage() === language.toLowerCase();
  }

  public isDefaultLanguageAndCountry (languageAndCountry: string): boolean {
    return this.getDefaultLanguageAndCountry()?.toLocaleLowerCase() === languageAndCountry.toLocaleLowerCase();
  }

  public getDefaultLanguage() {
    return this._languages[0];
  }

  public getDefaultLanguageAndCountry() {
    return this._languageAndCountries[0];
  }

  public getLanguageAndCountry(
    language: ValueOf<typeof languageCodes> | string | null | undefined = null,
    countryCode: Maybe<CountryCodeValue> = null): string {
    language = language || this.getDefaultLanguage();
    countryCode = countryCode || this.countryCode;

    return `${language?.toLowerCase()}-${countryCode?.toUpperCase()}`;
  }

  public determineLanguageAndCountry(languageAndCountryOrLanguage: string | null | undefined): string {
    const defaultLanguageAndCountry = this.getDefaultLanguageAndCountry();

    if (!languageAndCountryOrLanguage && defaultLanguageAndCountry) {
      return defaultLanguageAndCountry;
    }

    if (languageAndCountryOrLanguage?.length === LANGUAGE_AND_COUNTRY_LENGTH) {
      return languageAndCountryOrLanguage;
    }

    return this.getLanguageAndCountry(languageAndCountryOrLanguage);
  }

  public getLanguageFromExistingLanguageAndCountry(languageAndCountry: string): ValueOf<typeof languageCodes> | null {
    if (this._languageAndCountries.includes(languageAndCountry)) {
      return CountryLocalization.getLanguageFromLanguageAndCountry(languageAndCountry);
    }

    return null;
  }

  public static getLanguageFromLanguageAndCountry(languageAndCountry: string): ValueOf<typeof languageCodes> | null {
    const language = languageAndCountry.split('-')[0];

    if (language && (Object.values(languageCodes) as string[]).includes(language)) {
      return language as ValueOf<typeof languageCodes>;
    }

    return null;
  }

  /**
   * Returns a list of 5 characters locale, like `nl-BE`, `fr-FR` or `en-GB`
  */
  public getLanguageAndCountries() {
    return this._languageAndCountries;
  }

  /**
   * Returns a list of 2 characters languages, like 'sk', 'en', 'fr', 'nl', 'pt'
   */
  public getLanguages() {
    return this._languages;
  }

  /**
   *
   * Returns a list of accepted languages, each one in 5 characters format.
   * Accepted languages includes the language derived from active language, plus
   * additional accepted languages, which might be different from fallbacks languages.
   */
  public getAcceptedLanguages(activeLanguage: ValueOf<typeof languageCodes> | null | undefined = null): Array<string> {
    activeLanguage = activeLanguage || this.getDefaultLanguage();
    const acceptedLanguages = [this.getLanguageAndCountry(activeLanguage)];

    for (const additionalAcceptedLanguage of this._additionalAcceptedLanguages) {
      acceptedLanguages.push(
        this.getLanguageAndCountry(additionalAcceptedLanguage.language, additionalAcceptedLanguage.countryCode));
    }

    return acceptedLanguages;
  }

  public getDomain(context = null) {
    const envVar = `${publicEnvironmental.DOMAIN}_${this.countryCode.toUpperCase()}`;
    const domain = getEnvironmental(envVar, context || undefined);
    const adjustedDomain = processDomainForPreprod(domain);
    return adjustedDomain;
  }

  public getParcelPerformId(language: ValueOf<typeof languageCodes>): string | null {
    for (const parcelPerformByLanguage of this._parcelPerformByLanguage) {
      if (parcelPerformByLanguage.language === language) {
        // handling for multi language countries
        return parcelPerformByLanguage.parcelPerformId;
      }
    }
    // handling for single language countries
    return this._parcelPerformByLanguage?.[0]?.parcelPerformId || null;
  }

  public getBloomreachDetails(languageAndCountry: string): BloomreachKeyByLanguage {
    if (isProduction()) {
      const language = this.getLanguageFromExistingLanguageAndCountry(languageAndCountry);
      for (const bloomreachKey of this._bloomreachKeyByLanguage) {
        if (bloomreachKey.language === language) {
        // handling for multi language countries
          return bloomreachKey;
        }
      }
      // handling for single language countries
      return this._bloomreachKeyByLanguage?.[0] || { key: '' };
    } else {
      return {
        ...this._bloomreachKeyByLanguage?.[0],
        key: stageConfig.BLOOMREACH_API.DOMAIN_ID,
        similarProductsId: stageConfig.BLOOMREACH_API.SIMILAR_PRODUCTS_ID,
        lastSeenId: stageConfig.BLOOMREACH_API.LAST_SEEN_ID,
        cartRecommendationId: stageConfig.BLOOMREACH_API.CART_RECOMMENDATION_ID
      };
    }
  }

  public getConsentManagerScriptUrl(languageAndCountry: string): string | null {
    const language = this.getLanguageFromExistingLanguageAndCountry(languageAndCountry);
    for (const scriptUrl of this._consentManagerScriptUrls) {
      if (scriptUrl.language === language) {
        // handling for multi language countries
        return scriptUrl.url;
      }
    }
    // handling for single language countries
    return this._consentManagerScriptUrls?.[0]?.url || null;
  }
}

export default CountryLocalization;
