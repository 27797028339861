import localizationDefinitions from '../../i18n/localizationDefinitions';
import { getHttpInfo } from '../../helpers/requestHandler';

export const baseRoutesNameSeparator = '___';
export const localizedRoutesNameSeparator = `${baseRoutesNameSeparator}l___`;

function getLocaleRouteName(routeName: string, languageAndCountry: string) {
  const countryLocalization = localizationDefinitions.getByLanguageAndCountry(languageAndCountry);
  languageAndCountry = languageAndCountry?.toLocaleLowerCase();

  if (countryLocalization?.isMultiLanguage && !countryLocalization.isDefaultLanguageAndCountry(languageAndCountry)) {
    return `${routeName}${localizedRoutesNameSeparator}${languageAndCountry}`;
  }

  return `${routeName}${baseRoutesNameSeparator}${languageAndCountry}`;
}

function getNameWithoutLocale(givenRoute: string) {
  return givenRoute.split(baseRoutesNameSeparator)?.[0] ?? givenRoute;
}

export function transformRouteNameLocale(
  givenRoute: string, languageAndCountry: string) {
  return getLocaleRouteName(getNameWithoutLocale(givenRoute), languageAndCountry);
}

export function getLocalization(context: any) {
  const { req } = context;
  const { HOST } = getHttpInfo(req);
  return localizationDefinitions.getByDomain(HOST, context);
}
