import { useShippingProvider } from '@vsf-enterprise/commercetools';
import { ref, reactive, computed, onMounted } from '@nuxtjs/composition-api';
import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
import { useCartExtended } from '~/composables';
import { ShippingMethodsResponse } from '~/types/checkout/ShippingMethod';

export default () => {
  const loading = ref(false);
  const shippingMethodsDataKey = 'shipping-methods-data-key';
  const { $ct, $cache } = useVSFContext();
  const { cart } = useCartExtended();
  const methodsWithoutId = sharedRef<ShippingMethodsResponse['shippingMethods']>([], shippingMethodsDataKey);
  const {
    state,
    load
  } = useShippingProvider();
  const selectedShippingMethod = computed(
    () => state?.value?.response?.shippingMethod);

  const error = reactive<{
    loadMethods: null | string | Error;
    selectShippingMethod: null | string | Error;
    loadMethodsWithoutId: null | string | Error;
  }>({
    loadMethods: null,
    selectShippingMethod: null,
    loadMethodsWithoutId: null
  });

  const loadProviderIfNeeded = async () => {
    if (cart.value && !state.value) {
      await load();
    }
  };

  onMounted(async () => {
    await loadProviderIfNeeded();
  });

  const loadMethodsWithoutId = async () => {
    if (methodsWithoutId.value?.length) return;

    error.loadMethodsWithoutId = null;
    try {
      let shippingMethods = await $cache.data.get(shippingMethodsDataKey);

      if (!shippingMethods) {
        shippingMethods = await $ct.api.getShippingMethodsWithoutId();
        await $cache.data.set(shippingMethodsDataKey, shippingMethods, [shippingMethodsDataKey]);
      }

      methodsWithoutId.value = shippingMethods?.data?.shippingMethods;
    } catch (err) {
      Logger.error('error.loadMethodsWithoutId = true ', String(err));
      error.loadMethodsWithoutId = err;
    }
  };

  const isShippingSelected = computed(() => !!selectedShippingMethod.value?.zoneRates);

  return {
    isShippingSelected,
    loading,
    error,
    loadMethodsWithoutId,
    methodsWithoutId,
    selectedShippingMethod
  };
};
