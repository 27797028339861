module.exports = {
  CUSTOM_QUERIES: {
    UPDATE_CART_CUSTOM: { updateCart: 'custom-update-cart' },
    THANK_YOU_PAGE_ORDER: { getMyOrders: 'thank-you-page-order-query' },
    PRODUCT_SEARCH: {
      mainProductQuery: { products: 'main-product-query' },
      relatedProductQuery: { products: 'related-product-query' },
      extraGuaranteeQuery: { products: 'extra-guarantee-query' },
      tagManagerQuery: { products: 'tag-manager-query' },
      productListingQuery: { products: 'product-listing-query' }
    },
    CATEGORIES: {
      NAVIGATION: 'categories-navigation-query',
      LISTING: 'categories-listing-query',
      TREE: 'categories-tree-query'
    },
    REVIEWS: { reviews: 'custom-review-query' },
    SHIPPING_METHODS: { shippingMethods: 'custom-shipping-methods' },
    SET_MY_CUSTOMER_DEFAULT_SHIPPING_ADDRESS: {
      setDefaultShippingAddress: 'custom-set-default-shipping-address'
    },
    UPDATE_MY_CUSTOMER_BILLING_ADDRESS: {
      updateBillingAddress: 'custom-update-billing-address'
    },
    UPDATE_MY_CUSTOMER_SHIPPING_ADDRESS: {
      updateShippingAddress: 'custom-update-shipping-address'
    },
    SET_MY_CUSTOMER_DEFAULT_BILLING_ADDRESS: {
      setDefaultBillingAddress: 'custom-set-default-billing-address'
    }
  }
};
