import { computed } from '@nuxtjs/composition-api';
import { useVSFContext, Logger } from '@vue-storefront/core';
import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import config from '../../config';
import {
  useCartExtended,
  useStock,
  useUiNotification,
  useUiState,
  useStoreVsfExtended,
  useIntegrations,
  useExtraGuarantee,
  useExtraGuaranteePrice
} from '~/composables';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import { NotificationStatus } from '~/types/components/Notification/NotificationStatus';
import callFunctionWithRetries from '~/helpers/retry/callFunctionWithRetries';
import { GUARANTEE_PLACEMENT } from '~/constants/guaranteePlacement';
import { isTestProduct } from '~/helpers/product/isTestProduct';

type AddToCartParams = {
  product: ProductVariant;
  quantity: number;
  isWithExtraGuarantee?: boolean;
  triggeredIn?: string;
};

/**
 * Custom composable for adding product to cart from add to cart button
 */
export const useAddToCart = (slug: string, sku: string) => {
  const { i18n } = useVSFContext();
  const { error: cartError, cart, addItem, addExtraGuaranteeProduct } = useCartExtended();
  const { send: sendUiNotification } = useUiNotification();
  const { stocks: stocksFetched } = useStock(slug);
  const { toggleCart, toggleExtraGuarantee } = useUiState();
  const { isExtraGuaranteeEnabled } = useStoreVsfExtended();
  const { $cia } = useIntegrations();

  const { skuHasAddedExtraGuarantee, cartItemExtraGuaranteeExcluded } = useExtraGuarantee();
  const { getExtraGuaranteePercentage } = useExtraGuaranteePrice();

  const stock = computed(() => stocksFetched.value?.[0]?.availableQuantity || 0);

  const quantityInCart = computed(() => {
    return cart.value?.lineItems.find(lineItem =>
      sku && lineItem.variant?.sku === sku)?.quantity || 0;
  });

  const currentlyRemainingStock = computed(() => {
    return stock.value - quantityInCart.value;
  });

  const maxSingleProductQuantity = computed(() => {
    return config.MAX_SINGLE_PRODUCT_CART_QUANTITY - quantityInCart.value;
  });

  const availableQuantity = computed(() => {
    return Math.min(maxSingleProductQuantity.value, currentlyRemainingStock.value);
  });

  const isQuantitySelectionPossible = computed(() => {
    return availableQuantity?.value > 0 || (sku && isTestProduct(sku));
  });

  const addToCartButtonText = computed(() => {
    return isQuantitySelectionPossible.value ? 'Add to cart' : 'out-of-stock';
  });

  const addToCart = async ({ product, quantity, isWithExtraGuarantee, triggeredIn }: AddToCartParams) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    await callFunctionWithRetries(() => addItem({
      product,
      quantity,
      customQuery: CUSTOM_QUERIES.UPDATE_CART_CUSTOM,
      triggeredIn
    }));
    if (isExtraGuaranteeEnabled.value && isWithExtraGuarantee) {
      await addExtraGuaranteeProduct(sku);
    }
    if (
      isExtraGuaranteeEnabled.value &&
        !isWithExtraGuarantee &&
        !skuHasAddedExtraGuarantee(product.sku) &&
        !cartItemExtraGuaranteeExcluded(sku)
    ) {
      toggleExtraGuarantee();
      const percentage = getExtraGuaranteePercentage();
      try {
        $cia.event.viewGuarantee(product.sku, GUARANTEE_PLACEMENT.PDP, percentage);
      } catch (error) {
        Logger.error(`cia|viewGuarantee error: ${error}`);
      }
    } else {
      toggleCart(cart);
    }

    checkForErrors();
  };

  const hasAddToCartError = computed(() => !!cartError.value.addItem);

  const checkForErrors = () => {
    if (hasAddToCartError.value) {
      sendUiNotification({
        message: i18n.t('There was a problem with adding product to the cart. Please try again.'),
        id: 'cart-adding-problem',
        type: NotificationStatus.error,
        dismissible: true
      });
    }
  };

  return {
    addToCart,
    availableQuantity,
    isQuantitySelectionPossible,
    addToCartButtonText,
    stock
  };
};

export default useAddToCart;
