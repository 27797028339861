module.exports = {
  MAX_SINGLE_PRODUCT_CART_QUANTITY: 20,
  SEO_MANAGER_REDIRECTIONS_ENDPOINT: 'https://expondo-seo-manager.azurewebsites.net/api/redirections',
  GRAHAM_REVIEW_ENDPOINT: 'https://expondo-graham.azurewebsites.net/api/review',
  IMAGES_360_URL: 'https://assets.expondo.com/asset/360_photos',
  typesenseHost: 'l132pzkshfg69an4p.a1.typesense.net',
  BLOOMREACH_API: {},
  SALESFORCE_WEBFORM: {
    SUBMIT_URL: 'https://expondogmbh--uat.sandbox.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=',
    ORGANISATION_ID: '00DKJ000000GycF'
  }
};
