import { computed, ssrRef, useRoute, onBeforeUnmount } from '@nuxtjs/composition-api';
import { useMakeOrder } from '@vsf-enterprise/commercetools';
import { Logger, useVSFContext, sharedRef } from '@vue-storefront/core';
import type { Order, Payment } from '@vsf-enterprise/commercetools-types';
import { OnSubmitFn } from '@vsf-enterprise/adyen-commercetools/lib/types';
import { AdyenPaymentErrors } from '~/types/checkout/AdyenPaymentErrors';
import {
  useCartExtended,
  useCheckout,
  useIntegrations,
  usePayNowEvent
} from '~/composables';
import { buildThankYouSlug } from '~/helpers/routes/buildThankYouSlug';
import { getFirstChildOrSelf } from '~/helpers/dataStructure/getFirstChildOrSelf';
import { ConsentProps } from '~/types/integrations/cia/event/consent/ConsentProps';
import isString from '~/helpers/dataStructure/isString';
import { PAYMENT_METHODS } from '~/constants/paymentMethods';

type ErrorType = Maybe<ValueOf<typeof AdyenPaymentErrors>>;

export default function () {
  const { $cia } = useIntegrations();
  const { app: { router } } = useVSFContext();
  const {
    make,
    order,
    error: errorMakeOrder
  } = useMakeOrder();
  const {
    postEmail
  } = useCheckout();
  const {
    cart,
    setCart,
    reloadCart,
    updatePayment
  } = useCartExtended();
  const route = useRoute();
  const { callPayNowEvent } = usePayNowEvent();

  const errorMessage = sharedRef('', 'useCustomPaymentError');

  const makeCustomPayment = async <PaymentResponse extends {data: { payment: Payment }}>(
    paymentCt: PaymentResponse,
    setErrorCreatePayment: (hasError: boolean) => void,
    afterPay: (arg0: Object) => Object
  ) => {
    if (isString(paymentCt?.data?.payment?.id)) {
      await makePayment(paymentCt.data.payment.id, afterPay);
    } else {
      setErrorCreatePayment(true);
    }
    setError(null);
  };

  const loading = sharedRef(false, 'useCustomPaymentLoading');
  const setLoading = (value: boolean) => {
    loading.value = value;
  };

  const setErrorMessage = (msg: string) => {
    errorMessage.value = msg;
  };

  const processOrder = ({ order, typeOfConsent }:{ order: Order, typeOfConsent?: ConsentProps }) => {
    if (typeOfConsent) {
      try {
        $cia.event.consent(
          typeOfConsent,
          '',
          {
            action_page: 'checkout',
            upgradable_to: { action: 'accept', category: 'newsletter', valid_until: 'unlimited' }
          }
        );
      } catch (e) {
        Logger.error('cia_payment_error', e);
      }
    }
    router.push(buildThankYouSlug(order.id));
    setError(null);
    setLoading(true);
    setCart(null);
  };

  const queryError = getFirstChildOrSelf(route.value.query?.error);

  const ssrError = ssrRef<ErrorType>(queryError, 'useCustomPaymentError');
  const sharedError = sharedRef<ErrorType>(queryError, 'useCustomPaymentSharedError');

  const error = computed(() => sharedError.value || ssrError.value);

  const setError = (newError: ErrorType) => {
    ssrError.value = newError;
    sharedError.value = newError;
    if (!newError) return;
    // if not added, it throws a different cart version error
    reloadCart();
  };
  const setIsMalFormedError = () => {
    setError(AdyenPaymentErrors.MALFORMED_PRICE);
  };
  const setIsRefusedError = () => {
    setError(AdyenPaymentErrors.PAYMENT_ERROR);
  };
  const setIsEntityError = () => {
    setError(AdyenPaymentErrors.UNPROCESSABLE_ENTITY);
  };

  const makeOrder = async (afterPay: Function) => {
    await make({});

    if (order.value && !errorMakeOrder.value?.make) {
      await afterPay({ order: order.value });
    }
  };
  const beforePay = async (state?: Parameters<OnSubmitFn>[0]) => {
    const paymentMethod = state?.data?.paymentMethod?.type;
    const isGooglePayPayment = paymentMethod === PAYMENT_METHODS.GOOGLEPAY;
    if (!cart.value?.customerEmail) {
      await postEmail(!isGooglePayPayment);
    }

    if (!isGooglePayPayment) { // we already called this event inside Google Pay's onClick
      callPayNowEvent(paymentMethod);
    }
  };
  const makePayment = async (paymentId: Payment['id'], afterPay: Function) => {
    setLoading(true);
    if (paymentId) {
      try {
        await updatePayment(paymentId);
      } catch (error) {
        setLoading(false);
        return;
      }
      await beforePay();
      await makeOrder(afterPay);
    }
    setError(null);
    setLoading(false);
  };

  onBeforeUnmount(() => {
    if (errorMessage.value) {
      setErrorMessage('');
    }
    setError(null);
  });

  return {
    loading,
    setLoading,
    makePayment,
    makeCustomPayment,
    makeOrder,
    beforePay,
    error,
    queryError,
    setIsMalFormedError,
    setIsRefusedError,
    processOrder,
    setIsEntityError,
    setError,
    errorMessage,
    setErrorMessage
  };
}
